@import "../../scss/media-queries";
@import "../../scss/mixins/grid";
@import "../../scss/mixins/b";
@import "../../scss/fonts";


div.contact {
  width: 85%;
  margin: 50px auto 40px;

  > div.contact-intro {
    h1 {
      @include RalewayBold();
      font-size: 32px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      color: #11141a;
    }
  }

  div.form {
    padding: 40px 28px;
    border-radius: 5px;

    > div {
      margin: 0 0 20px;

      input, textarea {
        padding: 16px 10.5px 15px 16.5px;
        border-radius: 4px;

        @include LatoRegular();
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.13px;
        text-align: left;

        &::placeholder {
          color: #bbc5d5;
        }

        + span {
          color: red;
          @include LatoRegular();
        }
      }

      textarea {
        height: 236px;
        resize: none;
      }

      &:last-child {
        margin-top: 20px;
        text-align: center;

        button {
          padding: 10px 78.3px 11px 79.7px;
          border-radius: 5px;
          background-color: #2aaae1;
          border: 2px solid #2aaae1;
          cursor: pointer;
          outline: none;

          @include LatoRegular();
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.24;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;

          &:disabled {
            cursor: auto;
            background-color: rgba(45, 183, 242, 0.53);
            border: 2px solid rgba(45, 183, 242, 0.53);
          }
        }
      }
    }

  }
}

div.g-recaptcha {
}
