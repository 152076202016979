/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#dialog {
  font-size: 16px;

  ul {
    list-style: outside;
  }

  > div {
    border: 1px solid rgba(217, 217, 217, 0.67);
  }
}

.px-16 {
  padding-left: 4rem/* 64px */;
  padding-right: 4rem/* 64px */;
}

.border-box {
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.z-\[1000\] {
  z-index: 1000;
}

.py-8 {
  padding-top: 2rem/* 32px */;
  padding-bottom: 2rem/* 32px */;
}

.bg-clip-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(42, 170, 225);
}

.fixed {
  position: fixed;
}

.bottom-0 {
  bottom: 0px;
}

.text-white {
  --tw-text-opacity: 1;
  color: white;
}

.text-black {
  --tw-text-opacity: 1;
  color: black !important;
}

.w-full {
  width: 100%;
}

.text-5xl {
  font-size: 3rem/* 48px */;
  line-height: 1;
}

.mb-2 {
  margin-bottom: 1.25rem/* 8px */;
}

.font-\[\'Raleway_Bold\'\] {
  font-family: 'Raleway Bold';
}

.grid {
  display: grid;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.w-full {
  width: 100%;
}

.text-2xl {
  font-size: 2rem/* 24px */;
  line-height: 1.75rem/* 32px */;
}

.font-\[\'Lato_Regular\'\] {
  font-family: 'Lato Regular';
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.text-right {
  text-align: right;
}

.text-xl {
  font-size: 1.25rem/* 20px */;
  line-height: 1.75rem/* 28px */;
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.px-5 {
  padding-left: 1.25rem/* 20px */;
  padding-right: 1.25rem/* 20px */;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: white;
}

.font-\[\'Lato_Bold\'\] {
  font-family: 'Lato Bold';
}

.text-clip-blue {
  --tw-text-opacity: 1;
  color: rgb(42, 170, 225);
}

.rounded {
  border-radius: 0.5rem/* 4px */;
}

.py-3 {
  padding-top: 0.75rem/* 12px */;
  padding-bottom: 0.75rem/* 12px */;
}

.px-4 {
  padding-left: 1rem/* 16px */;
  padding-right: 1rem/* 16px */;
}

.cookie-btn {
  border: 1px solid;
  cursor: pointer;
}

#cookies {
  font-size: 16px;
  padding: 2em 4em;
}

.text-clip-blue {
  color: rgba(42, 170, 225, 1);
}

.bg-gray-200 {
  background-color: rgba(229, 231, 235, 1);
}

.rounded {
  border-radius: 0.25em;
}

#dialog, #cookies {

  h5 {
    font-size: 1em;
    text-align: left;
  }

  p, h5, ul, h2 {
    margin: 0 !important;
  }

  h2 {
    font-size: 1.5em;
    text-align: left;
    margin-bottom: 0.5em !important;
  }

  .space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5em * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5em * var(--tw-space-y-reverse));
  }

  .space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1em * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1em * var(--tw-space-y-reverse)) !important;
  }

  .space-x-2 > :not([hidden]) ~ :not([hidden]) {
    margin-right: calc(0.5rem * 0);
    margin-left: calc(0.5rem * calc(1 - 0));
  }

  .space-x-4 > :not([hidden]) ~ :not([hidden]) {
    margin-right: calc(1rem * 0);
    margin-left: calc(1rem * calc(1 - 0));
  }

  section {
    margin: 0 !important;
    padding: 0 !important;
  }

  button {
    font-family: "Lato Regular", Serif, Georgia, serif;
    appearance: none;
    border: none;
    vertical-align: middle;
    padding: 0.75em 1em;
    cursor: pointer;
    background-color: transparent;
    border-radius: 0.25em;
    font-size: 15px;

    &.border {
      border: 1px solid;
    }

    &.border-white {
      border-color: white;
    }

    &.text-white {
      color: white !important;
    }

    &.border-clip-blue {
      border-color: #2aaae1;
    }

    &.bg-clip-blue {
      background-color: #2aaae1;
    }

    &.bg-gray-200 {
      background-color: rgba(229, 231, 235, 1);
    }

    img {
      vertical-align: inherit;
    }
  }

  .font-\[\'Lato_Regular\'\] {
    font-family: 'Lato Regular', serif;
  }
  .py-8 {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .px-4 {
    padding-left: 1em;
    padding-right: 1em;
  }
  .w-\[50\%\] {
    width: 50%;
  }
  .min-h-\[70vh\] {
    min-height: 70vh;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .grid-cols-\[1fr_70px\] {
    grid-template-columns: 1fr 70px;
  }
}

.self-center {
  align-self: center;
}

.mx-auto {
  margin: 0 auto;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.border {
  border-width: 1px;
}

.rounded {
  border-radius: 0.25rem;
}
.self-center {
  align-self: center;
}

.self-end {
  align-self: end;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.self-end {
  align-self: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.w-full {
  width: 100%;
}
.flex {
  display: flex;
}
@media (min-width: 640px) {
  .sm\:w-\[50\%\] {
    width: 50%;
  }
}
