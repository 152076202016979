/**
 * @file Font declarations and mixins.
 */

@font-face {
  font-family: 'Exo2 Bold';
  src: url('../assets/fonts/Exo2-Bold.otf') format('opentype');
}
@mixin Exo2Bold() {
  font-family: 'Exo2 Bold', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 Regular';
  src: url('../assets/fonts/Exo2-Regular.otf') format('opentype');
}
@mixin Exo2Regular() {
  font-family: 'Exo2 Regular', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Exo2 Medium';
  src: url('../assets/fonts/Exo2-Medium.otf') format('opentype');
}
@mixin Exo2Medium() {
  font-family: 'Exo2 Medium', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Raleway Regular';
  src: url('../assets/fonts/Raleway-Regular.ttf') format('truetype');
}
@mixin RalewayRegular() {
  font-family: 'Raleway Regular', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Raleway Bold';
  src: url('../assets/fonts/Raleway-Bold.ttf') format('truetype');
}
@mixin RalewayBold() {
  font-family: 'Raleway Bold', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Lato Bold';
  src: url('../assets/fonts/Lato-Bold.ttf') format('truetype');
}
@mixin LatoBold() {
  font-family: 'Lato Bold', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}

@font-face {
  font-family: 'Lato Regular';
  src: url('../assets/fonts/Lato-Regular.ttf') format('truetype');
}
@mixin LatoRegular() {
  font-family: 'Lato Regular', 'Calibri', 'Helvetica Neue', 'Century Gothic', sans-serif;
}
